<template>
    <FormPC v-if="brandName === 'PremiumClub'" :login="login" :goToForgetPassPage="goToForgetPassPage"
        :createdMethod="createdMethod" :errorText="errorText" />
    <Form v-else :login="login" :goToRegistrationPage="goToRegistrationPage" :loadedAsIframe="loadedAsIframe"
        :goToForgetPassPage="goToForgetPassPage" :handelCancelClick="handelCancelClick" :createdMethod="createdMethod"
        :mSite="mSite" />
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import ApiService from '@/services/ApiService.js';
import VueService from '@/services/VueService';

export default {
    components: {
        Form: () => import('./components/Default/Form.vue'),
        FormPC: () => import('./components/PremiumClub/FormPC.vue')
    },
    data() {
        return {
            mSite: false,
            errorText: ''
        }
    },
    computed: {
        loadedAsIframe() {
            return window.self !== window.top;
        },
        ...mapGetters(['brandName'])
    },
    methods: {
        sleep(duration) {
            return new Promise((resolve) => setTimeout(resolve, duration));
        },
        goToRegistrationPage(trial) {
            const queryParams = window.location.search;
            const trialQuery = _.startsWith(queryParams, '?') ? `${queryParams}&trial=true` : '?trial=true';

            this.$router.push(`/Register${trial ? trialQuery : queryParams}`);
        },
        goToForgetPassPage() {
            const queryParams = window.location.search;
            this.$router.push(`/ForgotPass${queryParams}`);
        },
        handelCancelClick() {
            if (this.loadedAsIframe) {
                const queryStringParams = Object.fromEntries(new URLSearchParams(window.location.search));
                window.parent.postMessage("close", queryStringParams?.originTarget);
            }
        },
        handleLoginError(error, showErrorMessage = this.$t('global.loginErrorOccurred')) {
            this.authError = error.message || showErrorMessage;
            this.errorText = error.message || showErrorMessage;
            if (this.brandName !== 'PremiumClub') {
                VueService.showToastMessage({ message: this.authError, type: 'error' });
            }
        },

        async login(username, password, captchaToken) {
            try {
                const loginRes = await ApiService.authApi.login(username, password, captchaToken);
                if (loginRes.error) {
                    throw Error(loginRes.error);
                }
                await ApiService.clientsApi.reportLoginPortalSuccess(loginRes.token, username);

                this.loginSuccess(loginRes.token)
            } catch (error) {
                this.handleLoginError(error);
            }
        },
        loginSuccess(token) {
            this.setToken(token);
            
            window.turnstile.remove()

            if (localStorage.getItem('redirect')) {
                this.$router.push(localStorage.getItem('redirect'));
            } else {
                this.$router.push(`/Profile`);
            }
        },
        async createdMethod() {
            const loader = this.$loading.show();
            if (_.includes(window.location.host, 'checkout')) {
                this.mSite = true;
            }
            const queryStringParams = Object.fromEntries(new URLSearchParams(window.location.search));
            if (queryStringParams.activeTransaction !== undefined) {
                localStorage.setItem('activeTransaction', queryStringParams.activeTransaction);
            }
            if (queryStringParams.magicLink) {
                const loginRes = await ApiService.authApi.magicLinkLogin(queryStringParams.magicLink);
                if (loginRes.error) {
                    this.handleLoginError({ message: loginRes.error });
                } else {
                    this.loginSuccess(loginRes.token);
                }
            }
            await this.sleep(5);
            loader.hide();
        },
        ...mapActions(['setToken'])
    }
};
</script>
<style scoped>
.header-span {
    font-size: 12px;
    margin: 10px 0;
}

.default-class {
    color: white !important;
}

.error-message {
    color: #ff5252;
    font-size: 12px;
}
</style>
