/* eslint-disable */
import { apiRequest } from "./api.js";

const BASE_URL = "/api/public";

const getAvailablePaymentMethods = (transactionId) =>
  apiRequest({
    method: "post",
    endpoint: `${BASE_URL}/getAvailablePaymentMethods`,
    query: { transactionId },
    body: { transactionId },
  });

const getSelectedPaymentMethod = ({ transactionId, paymentMethodId }) =>
  apiRequest({
    method: "post",
    endpoint: `${BASE_URL}/selectPaymentMethod`,
    body: { transactionId, paymentMethodId },
  });

const applyPromocode = ({ transactionId, promocode }) =>
  apiRequest({
    method: "post",
    endpoint: `${BASE_URL}/applyPromoCode`,
    body: { transactionId, promoCode: promocode },
  });

const getCryptoAddress = ({ currency, transactionId }) =>
  apiRequest({
    method: "post",
    endpoint: `${BASE_URL}/getCryptoAddress`,
    body: {
      coin: currency,
      transactionId,
    },
  });

export default {
  getAvailablePaymentMethods,
  getSelectedPaymentMethod,
  applyPromocode,
  getCryptoAddress,
};
