<template>
    <div v-if="isActive" class="burger-container">
        <div class="burger-menu">
            <div class="logo-and-burger">
                <img class="burger-logo" src="./assets/logoPC.svg" alt="">
                <img @click="toggleActiveBurger" class="burger-icon" src="./assets/x-mark.svg" alt="">
            </div>
            <router-link v-for="link of links" :key="link.id" :to="link.href === '/Logout' ? '' : link.href" @click.native="toggleActiveBurger(link)"
                aria-current="true" class="burger-nav-link" :class="{
                    active: `/${activeRouteName}` === link.href
                }">
                <span class="burger-nav-name">{{ $t(link.name) }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        toggleActiveBurger: Function,
        isActive: Boolean,
        links: Array
    },
    data() {
        return {
            activeRouteName: this.$router.history.current.name,
        };
    }
}
</script>

<style scoped>
.burger-menu-icon {
    position: absolute;
    right: 16px;
    top: 22px;
    opacity: 0;
    cursor: pointer;
}

.burger-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.burger-menu::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.logo-and-burger {
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
}

.burger-logo {
    width: 70px;
}

.burger-icon {
    padding-bottom: 10px;
    cursor: pointer;
}

.burger-menu {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: 100%;
    padding: 12px 16px;
    height: 100vh;
    z-index: 200;
    background: url('./assets/bg-image.png');
    display: flex;
    flex-direction: column;
}

.burger-nav-link {
    text-decoration: none;
    margin-bottom: 32px;
    text-align: center;
}

.burger-nav-name {
    font-family: 'Wix Madefor Display', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.4);
}

.active span {
    color: white;
}

@media only screen and (max-width: 1500px) {
    .burger-menu-icon {
        opacity: 1;
    }
}

@media only screen and (max-width: 768px) {}
</style>