<template>
	<v-app>
		<BgTemplate v-if="!minimal" />
		<router-view v-if="haveLocalStorageAccess" />
		<v-alert v-else-if="!haveLocalStorageAccess && host !== 'premiumclub.me'" color="error" dark type="error" width="1000">
			Please dont use incognito mode.
		</v-alert>

		<message-container />
		<popup-container />
	</v-app>
</template>

<script>
import BgTemplate from '@/components/BgTemplate.vue';
import { mapActions, mapState } from 'vuex';
import chats from './utils/chats';
import PopupContainer from '@/components/PopupContainer.vue';

export default {
	components: { PopupContainer, BgTemplate },
	props: {
		brandConfig: Object,
	},
	data() {
		return {
			haveLocalStorageAccess: null,
			minimal: false,
			host: window.location.host
		};
	},
	computed: {
		...mapState({
			chatProvider: (state) => state.chatProvider
		}),
		loadedAsIframe() {
			return window.self !== window.top;
		}
	},
	methods: {
		...mapActions(['setBrandName', 'setBrandConfig', 'setChatProvider', 'setWidgetId'])
	},
	async created() {
    this.setBrandName(this.brandConfig.brandName);

		this.setBrandConfig(this.brandConfig);
		this.setChatProvider(this.brandConfig.chatProvider);
		this.setWidgetId(this.brandConfig.chatWidgetId);
		try {
			chats.getChatProvider(this.brandConfig.chatProvider, this.brandConfig.chatWidgetId);
			// eslint-disable-next-line no-unused-vars
			const local = localStorage;
			const minimal = this.$route.query.minimal ?? false;
			this.minimal = minimal === 'true';
			const backgroundColorOverride = this.$route.query.backgroundColor;
			if (this.minimal) {
				document.documentElement.setAttribute('data-minimal', this.minimal);
				document.documentElement.style.setProperty('--main-bg-color', '#141414');
				document.documentElement.style.setProperty('--wrapper-bg-color', '#141414');
			}
			if (backgroundColorOverride) {
				const bgColor = `#${backgroundColorOverride}`;
				document.documentElement.style.setProperty('--main-bg-color', bgColor);
				document.documentElement.style.setProperty('--wrapper-bg-color', bgColor);
			}

			this.haveLocalStorageAccess = true;
		} catch {
			this.haveLocalStorageAccess = false;
		}
	}
};
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition: background-color 0s 600000s, color 0s 600000s;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition: background-color 0s 600000s, color 0s 600000s;
}

:root {
	--main-bg-color: none;
	--wrapper-bg-color: #141414;
	overflow: auto;
}

.profile-sheet .col-md-2 {
	font-weight: 500;
}

.profile-sheet .col-md-10 {
	font-weight: 400;
}

.bottom-container {
	margin-top: 51px;
}

#app {
	width: 100%;
	background: var(--main-bg-color);
	white-space: pre-line;
}

[data-minimal="true"] body::-webkit-scrollbar {
	display: none;
}

.table> :not(:last-child)> :last-child>* {
	border: none;
}

.table-striped>tbody>tr:nth-of-type(odd) {
	--bs-table-accent-bg: #141414;
}

.table-striped>tbody>tr:nth-of-type(even) {
	--bs-table-accent-bg: #1d1d1d;
}

.form-control,
.form-select {
	background-color: #1d1d1d !important;
	color: rgba(255, 255, 255, 0.8) !important;
	font-size: 18px;
	line-height: 24px;
	border-radius: 4px !important;
	border: 1px solid rgba(246, 251, 253, 0.28) !important;
}

.btn {
	font-size: 14px;
	font-weight: 600;
}

.modal .modal-content {
	background-color: #141414;
	color: #fff;
}

.modal .btn-close {
	position: absolute;
	right: 0.5rem;
	top: 0.7rem;
	opacity: 1;
}

@media only screen and (max-width: 767px) {
	.page-content {
		display: flex;
		flex-direction: column;
	}

	.bottom-container {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 68px;
	}
}
</style>
